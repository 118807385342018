<template>
  <div class="category " :style="getImageUrl(category.picture)" @click="$emit('click')">
    <div class="category-title">
      <h1 v-if="lang_select == ''" class="category-title-text">{{ category.name}}</h1>
      <h1 v-if="lang_select == '_th' && category.name_th != null" class="category-title-text">{{ category.name_th }}</h1>
      <h1 v-if="lang_select == '_th' && category.name_th == null" class="category-title-text">{{ category.name }}</h1>
      <h1 v-if="lang_select == '_ja' && category.name_ja != null" class="category-title-text">{{ category.name_ja }}</h1>
      <h1 v-if="lang_select == '_ja' && category.name_ja == null" class="category-title-text">{{ category.name }}</h1>
      <h1 v-if="lang_select == '_cn' && category.name_cn != null" class="category-title-text">{{ category.name_cn }}</h1>
      <h1 v-if="lang_select == '_cn' && category.name_cn == null" class="category-title-text">{{ category.name }}</h1>
      <h1 v-if="lang_select == '_my' && category.name_cn != null" class="category-title-text">{{ category.name_my }}</h1>
      <h1 v-if="lang_select == '_my' && category.name_cn == null" class="category-title-text">{{ category.name }}</h1>
   </div>
  </div>
</template>

<script>
export default {
  props: ['category', 'lang_select'],
  methods: {
    getImageUrl(picture) {
      return `
      background-image: url('${picture}');
      background-color: #cccccc;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      `
    }
  }
}
</script>

<style lang="scss" scoped>
/* category item */

.category {
  padding: 0em;
  min-height: 350px;

  /* grid */
  grid-column: span 6;
  /* end grid */

  /* flex */
  display: flex;
  justify-content: center;
  align-items: center;
  /* end flex */

  background: lightgoldenrodyellow;
  cursor: pointer;
}
.category-title {
  background: rgba($color: #424242, $alpha: 0.6);
  min-height: 40%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.category-title-text {
  font-weight: 200;
  font-size: 1.4em;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .category {
    grid-column: span 12;
    min-height: 400px;
    background-size: contain;
    background-image: none;
  }
  .category-title {
    min-height: 30%;
  }
  .category-title-text {
    font-size: 1.2em;
    font-weight: 400;
  }
}
/* End category item */
</style>
